<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('routing_management.routing_management') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('routing_management.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control"
                       :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>
                  {{ $t('routing_management.is_active') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('routing_management.description') }}</label>
                <textarea v-model="data.description" class="form-control"
                          :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div class="row mt-5">
          <div class="col-lg-12">
            <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
              <h6 class="mt-2">{{ $t('routing_management.operations') }}</h6>
              <button type="button" class="btn btn-primary" @click="addRepeater"><i
                  class="fas fa-plus"></i>{{ $t('add_more') }}
              </button>
            </div>
            <div class="bg-white">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">{{ $t('routing_management.sequence_id') }}</th>
                  <th scope="col">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('routing_management.operation') }}</span>
                      <a href="/manufactories/operation-management/create" target="_blank"
                         class="btn btn-primary btn-sm p-1">
                        <i class="fa fa-plus add-new"></i>
                      </a>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('routing_management.workstation') }}</span>
                      <a href="/manufactories/workstation/create" target="_blank"
                         class="btn btn-primary btn-sm p-1">
                        <i class="fa fa-plus add-new"></i>
                      </a>
                    </div>
                  </th>
                  <th scope="col">{{ $t('routing_management.operation_time_hour') }}</th>
                  <th scope="col">{{ $t('routing_management.description') }}</th>
                  <th scope="col">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sub_operations" :key="index">
                  <td>
                    <input type="number" min="0" disabled v-model="item.sequence_id" class="form-control"
                           :placeholder="$t('routing_management.sequence_id')"/>
                    <!--                                        <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">-->
                    <!--                                            {{ validation.name[0] }}-->
                    <!--                                        </span>-->
                  </td>
                  <td>
                    <multiselect v-model="item.operation"
                                 :placeholder="$t('routing_management.operation')"
                                 label="name"
                                 track-by="id"
                                 :options="operations"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="selectItem(index, 'operation')">
                    </multiselect>
                  </td>
                  <td>
                    <multiselect v-model="item.workstation"
                                 :placeholder="$t('routing_management.workstation')"
                                 label="name"
                                 track-by="id"
                                 :options="workstations"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @input="selectItem(index, 'workstation')">
                    </multiselect>
                  </td>
                  <td>
                    <input type="number" min="0" v-model="item.operation_time_hour" class="form-control"
                           :placeholder="$t('routing_management.operation_time_hour')"/>
                  </td>
                  <td>
                    <textarea v-model="item.description" class="form-control"
                              :placeholder="$t('routing_management.description')"></textarea>
                  </td>

                  <td>
                    <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i
                        class="fas fa-trash text-danger"></i></button>
                  </td>
                </tr>


                </tbody>
              </table>
            </div>

          </div>
        </div>




        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-operation-management",
  data() {
    return {
      mainRoute: 'manufacturers/routing_managements',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        description: null,
        is_active: true,
      },
      sub_operations: [],
      sub_operations_repeater: {
        id: null,
        sequence_id: null,
        operation: null,
        operation_management_id: null,
        workstation: null,
        workstation_id: null,
        operation_time_hour: null
      },

      workstations: [],


      operations: [],

      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,
      last_sequence: 0,

    };
  },
  watch: {
    workstation: function (val) {
      if (val)
        this.data.workstation_id = val.id;
      else
        this.data.workstation_id = null;
    },
    quality_inspection: function (val) {
      if (val)
        this.data.quality_inspection_id = val.id;
      else
        this.data.quality_inspection_id = null;
    },

  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        operations: this.sub_operations,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'routing-management.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        operations: this.sub_operations,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'routing-management.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.description = response.data.data.description;
        this.data.is_active = response.data.data.is_active;

        if (response.data.data.operations && response.data.data.operations.length > 0) {
          this.sub_operations = response.data.data.operations;
        } else {
          this.addRepeater();
        }


      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getWorkstations() {
      ApiService.get(this.mainRouteDependency + "/workstations").then((response) => {
        this.workstations = response.data.data;
      });
    },
    getOperations() {
      ApiService.get(this.mainRouteDependency + "/operations").then((response) => {
        this.operations = response.data.data;
      });
    },
    // async getLastSequence() {
    //     await ApiService.get(this.mainRouteDependency + "/last_sequence").then((response) => {
    //         this.last_sequence = response.data.data ? response.data.data-1 : 0;
    //     });
    // },

    addRepeater() {
      this.last_sequence = this.last_sequence + 1;
      this.sub_operations_repeater.sequence_id = this.last_sequence;
      this.sub_operations.push(this.sub_operations_repeater);
      this.sub_operations_repeater = {
        id: null,
        sequence_id: null,
        operation: null,
        operation_management_id: null,
        workstation: null,
        workstation_id: null,
        operation_time_hour: null
      };
    },
    removeRepeater(index) {
      if (!this.sub_operations[index].id) {
        this.last_sequence = this.last_sequence - 1;
      }
      if (this.sub_operations.length > 1)
        this.sub_operations.splice(index, 1);
    },
    selectItem(index, type) {
      if (type == 'operation')
        this.sub_operations[index].operation_management_id = this.sub_operations[index].operation ? this.sub_operations[index].operation.id : null;
      else if (type == 'workstation')
        this.sub_operations[index].workstation_id = this.sub_operations[index].workstation ? this.sub_operations[index].workstation.id : null;
    },


    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.sub_operations_repeater.workstation = response.data.data.workstation;
        if (this.sub_operations[0]) {
          this.sub_operations[0].workstation = response.data.data.workstation;
        }
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.routing_management"),
      route: '/manufactories/routing-management'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // let promise = this.getLastSequence();

    this.getWorkstations();
    this.getOperations();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
      // Promise.all([promise]).then(()=>{
      this.addRepeater();
      // })


    }

  },
};
</script>